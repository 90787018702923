<template>
  <div class="admin" v-if="!isProcessing">
    <div class="admin__menu" v-for="item in items" :key="item.title">
      <admin-menu :item="item" />
    </div>
  </div>
</template>

<script>
import AdminMenu from '@/components/common/menu.vue'

export default {
  name: 'admin',
  components: { AdminMenu },
  data () {
    return {
      items: [
        { title: '管理者権限の付与', content: '管理者権限を付与できます', to: 'admin_add' },
        { title: '管理者権限の削除', content: '管理者権限を削除できます', to: 'admin_delete' }
      ]
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.admin {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: 0 $padding_width $padding_height;
  margin: $header_height auto 0 auto;
}
</style>
